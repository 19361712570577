/* Btn Primary */
.btn-primary {
  color: var(--color-white);
  background-color: #21b6ff;
  border-radius: 5px;
  cursor: pointer;
  transition: 500ms all ease-out;
  font-size: 16px;
  padding: 5px 20px;
  display: flex;
  align-items: center;
  border: 2px solid #21b6ff;
  max-width: 175px;
}
.btn-primary:hover {
  color: var(--color-white);
  background-color: transparent;
}
/* Btn Transparent */
.btn-transparent {
  color: var(--color-white);
  background-color: transparent;
  padding: 5px 20px;
  border-radius: 5px;
  border: 2px solid #21b6ff;
  cursor: pointer;
  transition: 500ms all ease-out;
}
.btn-transparent:hover {
  color: var(--color-white);
  background-color: #21b6ff;
}
/* Active */
.color--active {
  font-weight: bold;
  color: #21b6ff;
  margin: 15px 0 0 0;
}
.color--active:hover {
}
/* hamburger */
.line1,
.line2,
.line3 {
  height: 3px;
  width: 25px;
  margin: 5px;
  background-color: #21b6ff;
  transition: 500ms all;
}
.toggleBurger .line1 {
  transform: rotate(45deg) translateY(5px);
}
.toggleBurger .line2 {
  display: none;
}
.toggleBurger .line3 {
  transform: rotate(-43deg) translateY(-6px);
}
/* Nav */
.toggleNav {
  transform: translateX(0%);
}
/* Video */
.displayNone {
  display: none;
}
