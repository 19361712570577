@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --color-primary: #001e36;
  --color-secondary: #007bff;
  --color-light: #c5c5c5;
  --color-white: #ffffff;
  --box-shadow-primary: 0px 0px 10px rgba(21, 2, 74, 0.5);
  --box-shadow-secondary: 0px 2.8743px 17.2458px -0.718575px rgba(0, 0, 0, 0.2);
  --radius-primary: 5px;
  --border-secondary: 20px;
  --boder: 2px solid #001e36;
  --font-primary: "Poppins", sans-serif;
}
/*
  1. Use a more-intuitive box-sizing model.
*/
*,
*::before,
*::after {
  box-sizing: border-box;
}
/*
  2. Remove default margin
*/
* {
  margin: 0;
  padding: 0;
}
/*
  3. Allow percentage-based heights in the application
*/
html,
body {
  height: 100%;
  background-color: #f1f6fa;
  overflow-x: hidden;
  scroll-behavior: smooth;
  /*Comenta la línea (o dale el valor 'auto' a scroll behavior )
 para probar la diferencia con y sin scroll suavizado*/
}
/*
  Typographic tweaks!
  4. Add accessible line-height
  5. Improve text rendering
*/
body {
  font-family: var(--font-primary);
  -webkit-font-smoothing: antialiased;
}
/*
  6. Improve media defaults
*/
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}
/*
  7. Remove built-in form typography styles
*/
input,
button,
textarea,
select {
  font: inherit;
}
/*
  8. Avoid text overflows
*/
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}
a {
  color: inherit;
  text-decoration: none;
}
ul {
  list-style: none;
}
/*
  9. Create a root stacking context
*/
#root,
#__next {
  isolation: isolate;
}
/* width */
::-webkit-scrollbar {
}

/* Track */
::-webkit-scrollbar-track {
}

/* Handle */
::-webkit-scrollbar-thumb {
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
}
