.about {
  background-color: #fff;
}
.about-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 30px;
}
.about-container-box {
}
.about-container-box--img {
  width: 400px;
  object-fit: cover;
  border-radius: 5px;
}
.button {
  display: flex;
  gap: 10px;
}
.product-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #21b6ff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
}
.product-button:hover {
  background-color: #0056b3;
}
/* Estilos del ícono de flecha */
.flecha {
  margin-left: 8px; /* Espacio entre el texto y el ícono */
  font-size: 16px;
  animation: pulse 0.5s infinite; /* Aplicar la animación de pulso en bucle al pasar el mouse */
}
.product-button:hover .flecha {
  animation: none;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05); /* Escala ligeramente el botón hacia arriba */
  }
  100% {
    transform: scale(1);
  }
}
.about-container-text {
  max-width: 400px;
  margin: 20px 10px 20px 10px;
}
.about-container-text--h3 {
  font-size: 0.8rem;
  color: #21b6ff;
}
.about-container-text--h3 span {
  color: #21b6ff;
}
.about-container-text--h2 {
  color: #002c8f;
  line-height: 30px;
  padding-top: 10px;
}
.about-container-text--p {
  font-size: 0.9rem;
  line-height: 15px;
  padding: 40px 0px 20px 0px;
  color: #4d4a4a;
}
@media screen and (max-width: 730px) {
  .about-container {
    justify-content: center;
    flex-wrap: wrap;
  }
}
