.nav {
  max-width: 100vw;
  min-height: 60px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 100;
  backdrop-filter: blur(5px);
  background-color: #001e36aa;
}
.nav-logo {
  display: flex;
  gap: 1rem;
}
.nav-logo--img {
  width: 150px;
  height: 100px;
  object-fit: cover;
}
.nav-ul {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.nav-ul-li {
  margin: 30px 0;
}
.nav-ul-li--a {
  color: var(--color-white);
}
.nav-ul-li--a:hover {
  text-decoration: 1.5px underline var(--color-white);
}
.nav-btns {
  display: flex;
  gap: 1rem;
}
.hamburger {
  display: none;
  cursor: pointer;
  z-index: 1000;
}

@media screen and (max-width: 860px) {
  .nav-ul {
    position: fixed;
    top: 0;
    right: 0;
    width: 100vw;

    background-color: var(--color-primary);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transform: translateX(100%);
    transition: 1s all ease-in-out;
    height: 100vh;
  }
  .nav-btns {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .hamburger {
    display: block;
  }
}
