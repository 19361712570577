.featured {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0px 10px 100px 10px;
  background-color: #fff;
}
.featured--h2 {
  position: relative;
  font-size: 2rem;
  padding: 50px 0px;
  text-align: center;
  line-height: 30px;
  color: hsl(0, 2%, 30%);
}
.featured--h2 span {
  color: #21b6ff;
}
.wrapper {
  max-width: 900px;
  padding: 10px;
  position: relative;
}
.wrapper-carousel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
}
.wrapper-carousel-container {
  min-width: calc(100% / 3);
  min-height: 280px;
  margin-left: 10px;
}
.wrapper-carousel-container--img {
  width: 100%;
  height: 400px;
  object-fit: cover;
}
.wrapper-carousel img:first-child {
  margin-left: 0;
}
.wrapper--arrow {
  position: absolute;
  top: 50%;
  font-size: 2.2rem;
  padding: 8px;
  cursor: pointer;
  color: var(--color-white);
  background-color: #21b6ff;
  border-radius: 100%;
  transform: translateY(-50%);
}
.wrapper--arrow-left {
  left: -10px;
}
.wrapper--arrow-right {
  right: -10px;
}

@media screen and (max-width: 550px) {
  .wrapper-carousel-container {
    min-width: calc(100% / 2);
  }
}
@media screen and (max-width: 400px) {
  .wrapper-carousel-container {
    min-width: 100%;
  }
}
