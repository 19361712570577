.contact {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-primary);
  padding: 50px 10px;
}
.contact-content {
  width: 100%;
  padding: 10px;
}
.contact-content-contact {
  max-width: 1000px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 auto;
}
.contact-content-contact--h2 {
  font-size: 2rem;
  font-weight: bold;
}

.contact-content-contact--p {
  padding: 5px 0px;
}

.contact-content-contact-icon {
  display: flex;
  flex-direction: column;
}
.contact-content-contact-box {
}
.contact-content-contact-icon--a {
  font-size: 2.5rem;
  color: #21b6ff;
  transition: 1s all;
}
.contact-content-contact-icon--a:hover {
  color: var(--color-primary);
}
