.h {
  height: 100vh;
  position: relative;
}
.header {
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
}
/* wave */
.bg-color {
  width: 100%;
  height: 70%;
  position: absolute;
  top: 0;
  background-color: var(--color-primary);
  overflow-x: hidden;
}
.wave {
  width: 6000px;
  height: 100%;
  position: absolute;
  top: 145px;
  left: 0;
  background-image: url(../../static/wave/wd1.svg);
  background-position: bottom;
}
.w1 {
  animation: w1 7s linear infinite;
}
.w2 {
  opacity: 0.5;
  animation: w2 7s linear -0.125s infinite,
    desplazamiento 7s ease -0.125s infinite;
}
@keyframes w1 {
  0% {
    margin-left: 0px;
  }
  100% {
    margin-left: -1600px;
  }
}
@keyframes w2 {
  0% {
    margin-left: 0px;
  }
  100% {
    margin-left: -1600px;
  }
}
@keyframes desplazamiento {
  0%,
  100% {
    transform: translateY(-25px);
  }
  50% {
    transform: translateY(10px);
  }
}
/* wave */
.header-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #fff;
  position: relative;
}

.left-content {
  max-width: 500px;
  padding: 30px;
}
.left-content h3 {
  font-size: 0.8rem;
  margin-bottom: 10px;
}

.left-content h1 {
  font-size: 3rem;
  margin-bottom: 20px;
  line-height: 40px;
}

.left-content span {
  color: #21b6ff;
}
.buttons {
  display: flex;
  gap: 10px;
}
.product-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
}
.product-button:hover {
  background-color: #0056b3;
}
/* Estilos del ícono de flecha */
.flecha {
  margin-left: 8px; /* Espacio entre el texto y el ícono */
  font-size: 16px;
  animation: pulse 0.5s infinite; /* Aplicar la animación de pulso en bucle al pasar el mouse */
}
.product-button:hover .flecha {
  animation: none;
}
.product-button,
.boton.video-button {
  background-color: #21b6ff;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 300;
  transition: background-color 0.3s ease;
}

/* Estilos del ícono de video */
.icono-video {
  color: #fff;
  font-size: 20px;
}

/* Estilos del botón de video */
.boton.video-button {
  background-color: #21b6ff;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  animation: pulse 2s infinite;
}

.boton.video-button:hover {
  background-color: #0056b3;
}

/* Estilos del ícono de video */
.icono-video {
  color: #fff;
  font-size: 20px;
}

/* Animación de pulso */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05); /* Escala ligeramente el botón hacia arriba */
  }
  100% {
    transform: scale(1);
  }
}

/* Estilos de la izquierda */
.right-content {
}

.right-content--img {
  width: 300px;
  object-fit: cover;
}
@media (max-width: 700px) {
  .right-content {
    display: none;
  }
}
@media screen and (max-heigth: 840px) {
  .wave {
    top: 85px;
  }
}
