.product {
  max-width: 1000px;
  margin: 150px auto;
}
.product-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}
.product-img {
  max-width: 400px;
  max-height: 400px;
}
.product-img--img {
  max-width: 300px;
  max-height: 400px;
  padding: 10px;
}
.product-text {
  padding: 10px;
}
.product-text--h1 {
  font-size: 1.8rem;
}
.product-text--h3 {
  font-size: 1rem;
  padding: 5px 0px 30px 0px;
}
.product-text--ul {
}
.product-text--li {
  font-size: 0.9rem;
}
.product-description {
  max-width: 1000px;
  background-color: var(--color-primary);
  color: var(--color-white);
  padding: 15px;
}
.product-description--h2 {
  font-size: 0.9rem;
  padding: 10px 0px 15px 0px;
}
.product-description--p {
  font-size: 0.9rem;
}
