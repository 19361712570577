.products {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 200px 0px 100px 0px;
  width: 100%;
}
.products-content {
  max-width: 320px;
  min-width: 280px;
}
.products-content--img {
  border-radius: 5px;
}
.products-content--h3 {
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  font-size: 1rem;
}
