/* Information.css */
.info-container {
  width: 100%;
  height: 350px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 0px 0px 30px 0px;
}

.emoji-group {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
@media (max-width: 1035px) {
  .emoji-group {
    flex-direction: column;
  }
  .info-container {
    flex-direction: column;
  }
}
.emoji-item {
  margin-bottom: 20px;
  margin: 10px 10px;
  background-color: #21b6ff;
  width: 250px;
  padding: 10px;
  border-radius: 5px;
  color: var(--color-white);
}

.emoji {
  font-size: 1.5rem;
  margin-bottom: 8px;
  color: var(--color-white);
}

.info-text {
  text-align: left;
  max-width: 300px;
}

.info-text--h2 {
  font-size: 1.7rem;
  padding: 10px 25px;
  color: #001e36;
  text-transform: uppercase;
}

.info-text--h2 span {
  color: #21b6ff;
}

@media screen and (max-width: 760px) {
  .info-container {
    height: 600px;
  }
}
